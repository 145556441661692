import { ReactNode } from "react";
import { RootState } from "@src/redux/slices/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@src/redux/store";
import { RoleEnum } from "@src/openapi-generator";
import LoadingBackdrop from "@src/components/LoadingBackdrop";
import { fetchMyCompany } from "@src/utils";
import PermissionRequire from "@src/contexts/permissions/consumers/PermissionRequire";
import AccountTypeBoundary from "@src/contexts/permissions/plugins/AccountTypeBoundary";
import { TCampaignPermission } from "@src/types/permission";
import AgentRoleBoundary from "@src/contexts/permissions/plugins/AgentRoleBoundary";
import { AgentPermissionContext } from "..";
import { useFetchUserInfo } from "../hooks/useFetchUserInfo";
import { useFetchAgentPermission } from "../hooks/useFetchAgentPermission";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { MSG_ERROR } from "@src/constants/errorMessages";
import { errorSnackbarOption } from "@src/utils/snackbar";

export type LoginRequiredProps = {
  children?: ReactNode;
  url?: string;
  roles?: RoleEnum[];
  permissonCodes?: TCampaignPermission[];
};

const PermissionProvider = ({
  children,
  roles,
  permissonCodes,
}: LoginRequiredProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const auth = useSelector((state: RootState) => {
    return state.auth;
  });
  const me = useSelector((state: RootState) => {
    return state.user.me;
  });
  const permissionPlugins = permissonCodes
    ? [AccountTypeBoundary, AgentRoleBoundary]
    : [AccountTypeBoundary];

  useFetchUserInfo();
  const {
    isFetchAgentPermission,
    agentPermissions,
  } = useFetchAgentPermission();
  fetchMyCompany(dispatch);

  if (auth.isLoading) return <LoadingBackdrop open />;
  if (!auth.isLogin || !me) return null;
  return (
    <AgentPermissionContext.Provider
      value={{
        permissionInfo: agentPermissions,
        isLoading: isFetchAgentPermission,
      }}
    >
      <PermissionRequire
        permissionCodes={permissonCodes}
        allowedAccountTypes={roles}
        plugins={permissionPlugins}
        cbError={() => {
          enqueueSnackbar(MSG_ERROR.DONT_HAVE_PERMISSION, {
            ...errorSnackbarOption,
            preventDuplicate: true,
          });
          router.back();
        }}
      >
        {children}
      </PermissionRequire>
    </AgentPermissionContext.Provider>
  );
};

export default PermissionProvider;
