import { useContext } from "react";
import { AgentPermissionContext } from "..";

/**
 * useAgentPermission
 * @returns agent permission info for AgentPermissionContext
 */
export const useAgentPermission = () => {
  const values = useContext(AgentPermissionContext);

  return values;
};
