import { useEffect, useState } from "react";
import { RootState } from "@src/redux/slices/rootReducer";
import { useSelector } from "react-redux";
import {
  AgentCompanyDetail,
  CampaignApi,
  CompanyTypeEnum,
} from "@src/openapi-generator";
import { TAgentPermission } from "@src/types/permission";
import {
  autoRefreshAxiosInstance,
  normalize,
  useCampaign,
  useCampaignId,
} from "@src/utils";
import { selectMyCompany } from "@src/redux";

/**
 * useFetchAgentPermission
 */
export const useFetchAgentPermission = () => {
  const me = useSelector((state: RootState) => {
    return state.user.me;
  });
  const { company_type: userCompanyType } = me || {};
  const myCompany = useSelector(selectMyCompany) as AgentCompanyDetail[];
  const hasMyCompanyInfo = Array.isArray(myCompany) && !!myCompany[0];
  const campaign_id = useCampaignId();
  const campaign = useCampaign(campaign_id, true);
  const campaignCompanyId = campaign?.company;

  const [permission, setPermission] = useState<TAgentPermission | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userCompanyType !== CompanyTypeEnum.Agent) return;
    const agentCompanyId = (myCompany || []).find(
      (company) => company?.client?.id === campaignCompanyId || "empty"
    )?.id;
    if (campaign_id && hasMyCompanyInfo && agentCompanyId) {
      setIsLoading(true);
      new CampaignApi(undefined, undefined, autoRefreshAxiosInstance)
        .campaignPermissionRetrieve(campaign_id, agentCompanyId, undefined)
        .then((response) => {
          const normalizedData = normalize(
            response.data.perms || [],
            "codename"
          ).result as any;
          setPermission(normalizedData);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [campaign_id, userCompanyType, hasMyCompanyInfo]);

  return { agentPermissions: permission, isFetchAgentPermission: isLoading };
};
