import { ReactNode } from "react";
import { RootState } from "@src/redux/slices/rootReducer";
import { useSelector } from "react-redux";
import { CompanyTypeEnum } from "@src/openapi-generator";
import { TCampaignPermission, TAgentPermission } from "@src/types/permission";
import { useAgentPermission } from "../hooks/useAgentPermission";

type TProps = {
  children: ReactNode;
  permissionCodes?: TCampaignPermission[];
  cbError?: ({
    permissionCodes,
    userCompanyType,
    permission,
    hasPermission,
  }: {
    permissionCodes: TCampaignPermission[];
    userCompanyType: CompanyTypeEnum;
    permission: TAgentPermission;
    hasPermission: boolean;
  }) => void;
};
/**
 * AgentRoleBoundary
 * @description Limit Access of Agent-account
 * @param permissionCodes
 * @param cbError - Error call back on access deny found
 */
const AgentRoleBoundary = ({ children, permissionCodes, cbError }: TProps) => {
  const me = useSelector((state: RootState) => {
    return state.user.me;
  });
  const { company_type: userCompanyType } = me || {};
  const { permissionInfo: permission } = useAgentPermission();

  const hasPermission = (() => {
    if (!permissionCodes) return true;
    if (userCompanyType !== CompanyTypeEnum.Agent) return true;
    if (!permission) return false;
    if (
      !permission.access_campaign.enabled ||
      isPermissionExpired("access_campaign")
    )
      return false;
    const _hasPerm = checkPermission();
    if (_hasPerm) return true;
    return false;
  })();

  if (!hasPermission) {
    if (cbError && !!permissionCodes && !!userCompanyType && !!permission)
      cbError({
        permissionCodes,
        userCompanyType,
        permission,
        hasPermission: checkPermission(),
      });
    return null;
  }
  return <>{children}</>;

  function isPermissionExpired(permCode: TCampaignPermission) {
    if (!permCode) return false;
    const today = new Date();
    const _perm = permission?.[permCode];
    const startPermission = _perm?.start;
    const endPermission = _perm?.end;
    let isExpired = false;

    if (startPermission && Number(new Date(startPermission)) > Number(today))
      isExpired = true;
    if (endPermission && Number(new Date(endPermission)) < Number(today))
      isExpired = true;

    return isExpired;
  }

  function isPermissionEnabled(permCode: TCampaignPermission) {
    if (!permission) return false;
    if (permission[permCode].enabled) return true;
    return false;
  }

  function checkPermission() {
    let i;
    if (!permissionCodes) return true;

    for (i = 0; i < permissionCodes.length; i += 1) {
      const hasAnyPermissionEnable =
        isPermissionEnabled(permissionCodes[i]) &&
        !isPermissionExpired(permissionCodes[i]);
      if (hasAnyPermissionEnable) return true;
    }

    return false;
  }
};

export default AgentRoleBoundary;
export type TAgentRoleBoundary = typeof AgentRoleBoundary;
