import { ReactNode, useEffect } from "react";
import { DrawerList, DrawerListItem } from "./type";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import RateReviewIcon from "@material-ui/icons/RateReview";
import TvIcon from "@material-ui/icons/Tv";
import RadioIcon from "@material-ui/icons/Radio";
import NewspaperIcon from "@src/icons/NewspaperIcon";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import TrainIcon from "@material-ui/icons/Train";
import WebIcon from "@material-ui/icons/Web";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import SettingsIcon from "@material-ui/icons/Settings";
import { CodenameEnum, Permission } from "@src/openapi-generator";
import { useAgentPermission } from "@src/utils";

const mediaType = [
  "tvspot",
  "tvtime",
  "radiospot",
  "radiotime",
  "newspaper",
  "magazine",
  "traffic",
  "web",
  "topic",
] as const;

type MediaType = typeof mediaType[number];

const mediaRepot: {
  id: MediaType;
  icon: ReactNode;
  text: string;
  isViewOnly?: boolean;
}[] = [
  {
    id: "tvspot",
    icon: <TvIcon />,
    text: "TV Spot",
  },
  {
    id: "tvtime",
    icon: <TvIcon />,
    text: "TV Time",
  },
  {
    id: "radiospot",
    icon: <RadioIcon />,
    text: "ラジオ Spot",
  },
  {
    id: "radiotime",
    icon: <RadioIcon />,
    text: "ラジオ Time",
  },
  {
    id: "newspaper",
    icon: <NewspaperIcon />,
    text: "新聞",
  },
  {
    id: "magazine",
    icon: <MenuBookIcon />,
    text: "雑誌",
  },
  {
    id: "traffic",
    icon: <TrainIcon />,
    text: "交通",
  },
  {
    id: "web",
    icon: <WebIcon />,
    text: "Web",
  },
  {
    id: "topic",
    icon: <ChatBubbleIcon />,
    text: "話題化",
    isViewOnly: true,
  },
];

const orientationMenu: DrawerList = {
  id: "orientation_list",
  subheader: "オリエンテーション",
  listItems: [
    {
      id: "orientation",
      text: "オリエンテーション",
      children: [
        {
          id: "orientation_edit",
          icon: <EditIcon />,
          text: "編集",
        },
        {
          id: "orientation_view",
          icon: <VisibilityIcon />,
          text: "閲覧",
        },
      ],
    },
  ],
};

const presentationMenu: DrawerList = {
  id: "presentation_list",
  subheader: "プレゼンテーション",
  listItems: [
    {
      id: "presentation",
      text: "プレゼンテーション",
      children: [
        {
          id: "presentation_edit",
          icon: <EditIcon />,
          text: "編集",
        },
        {
          id: "presentation_eval",
          icon: <RateReviewIcon />,
          text: "評価",
        },
        {
          id: "presentation_view",
          icon: <VisibilityIcon />,
          text: "閲覧",
        },
      ],
    },
  ],
};

const actionresultMenu: DrawerList = {
  id: "actionresult_list",
  subheader: "アクション&リザルト",
  listItems: [
    {
      id: "actionresult",
      text: "アクション&リザルト",
      children: [
        {
          id: "overview_edit",
          icon: <EditIcon />,
          text: "概要編集",
        },
        {
          id: "general_comment_edit",
          icon: <EditIcon />,
          text: "各評価編集",
        },
        {
          id: "cost_forecast_edit",
          icon: <EditIcon />,
          text: "費用の予実・総評編集",
        },
        {
          id: "actionresult_view",
          icon: <VisibilityIcon />,
          text: "閲覧",
        },
      ],
    },
  ],
};

const mediarepotMenu: DrawerList = {
  id: "media_report",
  subheader: "媒体別レポート",
  listItems: mediaRepot.map((v) => ({
    ...v,
    children: v.isViewOnly
      ? [
          {
            id: `${v.id}_edit`,
            icon: <EditIcon />,
            text: "編集",
          },
        ]
      : [
          {
            id: `${v.id}_edit`,
            icon: <EditIcon />,
            text: "編集",
          },
          {
            id: `${v.id}_view`,
            icon: <VisibilityIcon />,
            text: "閲覧",
          },
        ],
  })),
};

const settingMenu: DrawerList = {
  id: "setting",
  subheader: "設定",
  listItems: [
    {
      id: "permission",
      icon: <SettingsIcon />,
      text: "権限管理",
    },
  ],
};

export const clientDrawerList: DrawerList[] = [
  orientationMenu,
  presentationMenu,
  actionresultMenu,
  mediarepotMenu,
  settingMenu,
];

export const getClientDrawerList = () => {
  return clientDrawerList;
};

const checkPermission = (perm: Permission): boolean => {
  if (!perm.enabled) return false;
  const now = new Date();
  if (perm.start && new Date(perm.start) > now) return false;
  if (perm.end && new Date(perm.end) < now) return false;
  return true;
};

export const getAgentDrawerList = (
  campaignId: string,
  callback: (list?: DrawerList[]) => void,
  preventFetchCampaign?: boolean
) => {
  const agentDrawerList: DrawerList[] = [];
  const permission = useAgentPermission(campaignId, preventFetchCampaign);
  useEffect(() => {
    if (permission) {
      const perms = permission.perms;
      let orientationView = false;
      let actionresultView = false;
      let overviewEdit = false;
      const mediaView: any = mediaType.reduce(
        (obj, x) => Object.assign(obj, { [x]: false }),
        {}
      ) as any;
      const mediaEdit: any = mediaType.reduce(
        (obj, x) => Object.assign(obj, { [x]: false }),
        {}
      ) as any;
      if (perms === undefined) return undefined;
      for (let i = 0; i < perms.length; i++) {
        const perm = perms[i];
        if (perm.codename === CodenameEnum.AccessCampaign && !perm.enabled) {
          return callback(undefined);
        }
        if (checkPermission(perm)) {
          switch (perm.codename) {
            case CodenameEnum.ViewOrientationWithCompetition:
            case CodenameEnum.ViewOrientationWithoutCompetition:
              orientationView = true;
              break;
            case CodenameEnum.ViewConcept:
            case CodenameEnum.ViewStrategy:
            case CodenameEnum.ViewKeyVisual:
            case CodenameEnum.ViewPurpose:
            case CodenameEnum.ViewMainTarget:
            case CodenameEnum.ViewSubTarget:
            case CodenameEnum.ViewCost:
            case CodenameEnum.ViewMediaCost:
            case CodenameEnum.ViewKpi:
            case CodenameEnum.ViewPromotion:
            case CodenameEnum.ViewSns:
            case CodenameEnum.ViewPr:
            case CodenameEnum.ViewImprovement:
            case CodenameEnum.ComparePurpose:
            case CodenameEnum.CompareMainTarget:
            case CodenameEnum.CompareSubTarget:
            case CodenameEnum.CompareCost:
            case CodenameEnum.CompareMediaCost:
            case CodenameEnum.CompareKpi:
            case CodenameEnum.ComparePromotion:
            case CodenameEnum.CompareSns:
            case CodenameEnum.ComparePr:
              actionresultView = true;
              break;
            case CodenameEnum.ChangeConcept:
            case CodenameEnum.ChangePromotion:
            case CodenameEnum.ChangeStrategy:
            case CodenameEnum.ChangeKeyVisual:
              overviewEdit = true;
              break;
            case CodenameEnum.ViewWithReviewTvspot:
            case CodenameEnum.ViewWithoutReviewTvspot:
              mediaView.tvspot = true;
              break;
            case CodenameEnum.ChangeTvspot:
              mediaEdit.tvspot = true;
              break;
            case CodenameEnum.ViewWithReviewTvtime:
            case CodenameEnum.ViewWithoutReviewTvtime:
              mediaView.tvtime = true;
              break;
            case CodenameEnum.ChangeTvtime:
              mediaEdit.tvtime = true;
              break;
            case CodenameEnum.ViewWithReviewRadiospot:
            case CodenameEnum.ViewWithoutReviewRadiospot:
              mediaView.radiospot = true;
              break;
            case CodenameEnum.ChangeRadiospot:
              mediaEdit.radiospot = true;
              break;
            case CodenameEnum.ViewWithReviewRadiotime:
            case CodenameEnum.ViewWithoutReviewRadiotime:
              mediaView.radiotime = true;
              break;
            case CodenameEnum.ChangeRadiotime:
              mediaEdit.radiotime = true;
              break;
            case CodenameEnum.ViewWithReviewNewspaper:
            case CodenameEnum.ViewWithoutReviewNewspaper:
              mediaView.newspaper = true;
              break;
            case CodenameEnum.ChangeNewspaper:
              mediaEdit.newspaper = true;
              break;
            case CodenameEnum.ViewWithReviewMagazine:
            case CodenameEnum.ViewWithoutReviewMagazine:
              mediaView.magazine = true;
              break;
            case CodenameEnum.ChangeMagazine:
              mediaEdit.magazine = true;
              break;
            case CodenameEnum.ViewWithReviewTraffic:
            case CodenameEnum.ViewWithoutReviewTraffic:
              mediaView.traffic = true;
              break;
            case CodenameEnum.ChangeTraffic:
              mediaEdit.traffic = true;
              break;
            case CodenameEnum.ViewWithReviewWeb:
            case CodenameEnum.ViewWithoutReviewWeb:
              mediaView.web = true;
              break;
            case CodenameEnum.ChangeWeb:
              mediaEdit.web = true;
              break;
            case CodenameEnum.ViewWithReviewTopic:
              mediaEdit.topic = true;
              break;
          }
        }
      }
      if (orientationView) {
        agentDrawerList.push({
          id: "orientation_list",
          subheader: "オリエンテーション",
          listItems: [
            {
              id: "orientation",
              text: "オリエンテーション",
              children: [
                {
                  id: "orientation_view",
                  icon: <VisibilityIcon />,
                  text: "閲覧",
                },
              ],
            },
          ],
        });
      }
      if (actionresultView && !overviewEdit) {
        agentDrawerList.push({
          id: "actionresult_list",
          subheader: "アクション&リザルト",
          listItems: [
            {
              id: "actionresult",
              text: "アクション&リザルト",
              children: [
                {
                  id: "actionresult_view",
                  icon: <VisibilityIcon />,
                  text: "閲覧",
                },
              ],
            },
          ],
        });
      }
      if (actionresultView && overviewEdit) {
        agentDrawerList.push({
          id: "actionresult_list",
          subheader: "アクション&リザルト",
          listItems: [
            {
              id: "actionresult",
              text: "アクション&リザルト",
              children: [
                {
                  id: "overview_edit",
                  icon: <EditIcon />,
                  text: "概要 編集",
                },
                {
                  id: "actionresult_view",
                  icon: <VisibilityIcon />,
                  text: "閲覧",
                },
              ],
            },
          ],
        });
      }
      if (!actionresultView && overviewEdit) {
        agentDrawerList.push({
          id: "actionresult_list",
          subheader: "アクション&リザルト",
          listItems: [
            {
              id: "actionresult",
              text: "アクション&リザルト",
              children: [
                {
                  id: "overview_edit",
                  icon: <EditIcon />,
                  text: "概要 編集",
                },
              ],
            },
          ],
        });
      }
      agentDrawerList.push({
        id: "media_report",
        subheader: "媒体別レポート",
        listItems: mediaRepot
          .map((v): DrawerListItem | undefined => {
            if (mediaEdit[v.id] || mediaView[v.id]) {
              const children: DrawerListItem[] = [];
              if (mediaEdit[v.id]) {
                children.push({
                  id: `${v.id}_edit`,
                  icon: <EditIcon />,
                  text: "編集",
                });
              }
              if (mediaView[v.id]) {
                children.push({
                  id: `${v.id}_view`,
                  icon: <VisibilityIcon />,
                  text: "閲覧",
                });
              }
              return {
                ...v,
                children,
              };
            }
            return undefined;
          })
          .filter((v): v is DrawerListItem => v !== undefined),
      });
      callback(agentDrawerList);
    }
  }, [JSON.stringify(permission)]);
};

const getIdArrayFromItem = (item: DrawerListItem): string[] => {
  return [item.id].concat(
    item.children
      ? item.children.flatMap<string>((child) => getIdArrayFromItem(child))
      : []
  );
};

const getIdArrayFromList = (list: DrawerList): string[] => {
  return [list.id].concat(
    list.listItems.flatMap<string>((item) => getIdArrayFromItem(item))
  );
};

export const getIdArrayFromListArray = (data: DrawerList[]): string[] => {
  return data.flatMap<string>((list: DrawerList) => getIdArrayFromList(list));
};
