import { TAgentPermission } from "@src/types/permission";
import { createContext } from "react";

export const AgentPermissionContext = createContext<{
  permissionInfo: TAgentPermission | undefined;
  isLoading: boolean;
}>({
  permissionInfo: undefined,
  isLoading: false,
});
