import { useEffect } from "react";
import { RootState } from "@src/redux/slices/rootReducer";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@src/redux/store";
import { fetchMe } from "@src/redux/slices/userSlice";

/**
 * useFetchUserInfo
 */
export const useFetchUserInfo = () => {
  const auth = useSelector((state: RootState) => {
    return state.auth;
  });
  const me = useSelector((state: RootState) => {
    return state.user.me;
  });
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!auth.isLoading) {
      if (!auth.isLogin) {
        router.replace("/login");
      }
      if (auth.isLogin && !me) {
        dispatch(fetchMe());
      }
    }
  }, [dispatch, JSON.stringify(auth)]);
};
