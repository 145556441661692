import { ReactNode } from "react";
import { RootState } from "@src/redux/slices/rootReducer";
import { useSelector } from "react-redux";
import { RoleEnum } from "@src/openapi-generator";

type TProps = {
  children: ReactNode;
  allowedAccountTypes?: RoleEnum[];
};
/**
 * AccountTypeBoundary
 * @description Limit account-types access
 * @param allowedAccountTypes
 */
const AccountTypeBoundary = ({ children, allowedAccountTypes }: TProps) => {
  const me = useSelector((state: RootState) => {
    return state.user.me;
  });

  const isAllowedAccountType = (() => {
    if (!allowedAccountTypes) {
      return true;
    }
    if (!me) {
      return false;
    }
    if (me.role && allowedAccountTypes.includes(me.role)) {
      return true;
    }
    return false;
  })();

  if (!isAllowedAccountType) return null;
  return <>{children}</>;
};

export default AccountTypeBoundary;
export type TAccountTypeBoundary = typeof AccountTypeBoundary;
