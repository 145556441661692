import { useState, Fragment } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "./Drawer";
import { Button } from "@material-ui/core";
import { useAppDispatch } from "@src/redux/store";
import { clearAll, logout } from "@src/redux/actions";
import { useSelector } from "react-redux";
import { RootState } from "@src/redux/slices/rootReducer";

import BusinessIcon from "@material-ui/icons/Business";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Link from "./Link";
import { useSnackbar } from "notistack";
import { successSnackbarOption } from "@src/utils/snackbar";
import { selectMyCompany } from "@src/redux";

const logoSize = {
  height: 425,
  width: 596,
} as const;

const logoAspect = logoSize.width / logoSize.height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up("md")]: {
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    logo: {
      position: "relative",
      height: theme.mixins.toolbar.minHeight,
      width: `${logoAspect * 48}px`,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    title: {
      flexGrow: 1,
    },
  })
);

type Props = {
  showDrawer?: boolean;
  preventFetchCampaign?: boolean;
};

export default function ButtonAppBar(props: Props) {
  const { showDrawer = true } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: RootState) => state.user);
  const company = useSelector(selectMyCompany);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link href="/" color="inherit">
              adlog
            </Link>
          </Typography>
          {company && "name" in company ? (
            <>
              <BusinessIcon />
              {company.name}{" "}
            </>
          ) : null}
          {user.me ? (
            <>
              <AccountCircleIcon style={{ marginLeft: 15 }} />
              <Link href="/profile" color="inherit">
                {user.me.name}
              </Link>
            </>
          ) : null}
          <Button
            onClick={() => {
              dispatch(logout());
              dispatch(clearAll());
              enqueueSnackbar(`ログアウトしました。`, successSnackbarOption);
            }}
            style={{ marginLeft: 5 }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {showDrawer ? (
        <Drawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          preventFetchCampaign={props.preventFetchCampaign}
        />
      ) : null}
    </Fragment>
  );
}
