import { useState, Fragment, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Divider } from "@material-ui/core";
import { DrawerList, DrawerListItem } from "./type";
import {
  getAgentDrawerList,
  getClientDrawerList,
  getIdArrayFromListArray,
} from "./data";
import { useRouter } from "next/router";
import { selectMyUser } from "@src/redux";
import { useSelector } from "react-redux";
import { CompanyTypeEnum } from "@src/openapi-generator";
import { useCampaignId } from "@src/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

type CollapseState = {
  [key: string]: boolean;
};

const makeInitialCollapseState = (str_ary: string[]): CollapseState => {
  return str_ary.reduce((obj, x) => Object.assign(obj, { [x]: false }), {});
};

export default function NestedList({
  preventFetchCampaign,
}: {
  preventFetchCampaign?: boolean;
}) {
  const classes = useStyles();
  const campaignId = useCampaignId();
  const me = useSelector(selectMyUser);
  const [data, setData] = useState<DrawerList[] | undefined>();
  // const listIdArray: string[] = getIdArrayFromListArray(data);
  const [listIdArray, setListIdArray] = useState<string[]>([]);
  const [collapseState, setCollapseState] = useState<CollapseState>(() =>
    makeInitialCollapseState(listIdArray)
  );
  const handleCollapse = (id: string) => {
    setCollapseState({ ...collapseState, [id]: !collapseState[id] });
  };
  if (me?.company_type === CompanyTypeEnum.Client && !data) {
    setData(getClientDrawerList());
    setListIdArray(getIdArrayFromListArray(getClientDrawerList()));
  } else if (me?.company_type === CompanyTypeEnum.Agent && campaignId) {
    getAgentDrawerList(
      campaignId,
      (list) => {
        if (list && data === undefined) {
          setData(list);
          setListIdArray(getIdArrayFromListArray(list));
        } else {
          console.log("error");
        }
      },
      preventFetchCampaign
    );
  }
  useEffect(() => {
    setCollapseState(makeInitialCollapseState(listIdArray));
  }, [listIdArray]);
  const router = useRouter();
  const pathname = router.pathname;
  const split_pathname = pathname.split("/");
  const selected_child_id = split_pathname[3];
  const selected_id = selected_child_id.split("_")[0];

  /* className={classes.root} */
  return (
    <Fragment>
      {data?.map((list: DrawerList, index: number) => (
        <Fragment key={list.id}>
          {index !== 0 ? <Divider /> : null}
          <List
            component="nav"
            id={list.id}
            subheader={
              list.subheader ? (
                <ListSubheader component="div">{list.subheader}</ListSubheader>
              ) : undefined
            }
          >
            {list.listItems.map((item: DrawerListItem) => (
              <Fragment key={item.id}>
                <ListItem
                  button
                  id={item.id}
                  selected={item.id === selected_id}
                  onClick={
                    item.children
                      ? () => handleCollapse(item.id)
                      : () => {
                          router.push(`/campaign/${campaignId}/${item.id}`);
                        }
                  }
                >
                  {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
                  <ListItemText primary={item.text} />
                  {item.children ? (
                    collapseState[item.id] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItem>
                {item.children ? (
                  <Collapse
                    in={collapseState[item.id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.children.map((childItem: DrawerListItem) => (
                        <ListItem
                          button
                          key={childItem.id}
                          className={classes.nested}
                          selected={childItem.id === selected_child_id}
                          onClick={
                            childItem.children
                              ? () => handleCollapse(childItem.id)
                              : () => {
                                  router.push(
                                    `/campaign/${campaignId}/${childItem.id}`
                                  );
                                }
                          }
                        >
                          {childItem.icon ? (
                            <ListItemIcon>{childItem.icon}</ListItemIcon>
                          ) : null}
                          <ListItemText primary={childItem.text} />
                          {childItem.children ? (
                            collapseState[childItem.id] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                ) : null}
              </Fragment>
            ))}
          </List>
        </Fragment>
      ))}
    </Fragment>
  );
}
