import { ReactNode } from "react";
import AgentRoleBoundary from "../plugins/AgentRoleBoundary";
import { TPluginProps, TPlugins } from "../plugins";
import AccountTypeBoundary from "../plugins/AccountTypeBoundary";

/**
 * PermissionRequire
 * @param permissionCodes
 * @param allowedAccountTypes
 * @param plugins - Permission Boundaries plugins
 */
const PermissionRequire = ({
  children,
  plugins = [AccountTypeBoundary, AgentRoleBoundary],
  ...rest
}: {
  children: ReactNode;
  plugins?: TPlugins[];
} & TPluginProps) =>
  plugins.reduce(
    (acc, Plugin) => <Plugin {...rest}>{acc}</Plugin>,
    <>{children}</>
  );

export default PermissionRequire;
