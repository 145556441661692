import * as React from "react";
import { OptionsObject, useSnackbar } from "notistack";

export function enqueueSnackbar(
  message: React.ReactNode,
  options?: OptionsObject | undefined
): string | number {
  const { enqueueSnackbar } = useSnackbar();
  return enqueueSnackbar(message, options);
}

export const successSnackbarOption: OptionsObject = {
  variant: "success",
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};

export const errorSnackbarOption: OptionsObject = {
  variant: "error",
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};

export function enqueueSuccessFileUploadSnackbar(
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined
  ) => React.ReactText,
  fileName: React.ReactNode
): string | number {
  const message = `"${fileName}"をアップロードしました`;
  return enqueueSnackbar(message, {
    variant: "success",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  });
}
