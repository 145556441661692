export const MSG_ERROR = {
  NO_CAMPAIGN_SELECTED: "選択されているキャンペーンがありません。",
  DONT_HAVE_PERMISSION: "無許可",
  HAS_NO_DATA: "許可データなし",
  DELETE_USER_SUCCESSFULLY: "ユーザーを正常に削除します",
  END_DATE_LOWER_THEN_START_DATE: "終了日時は開始日時以降を設定してください。",
  HIGH_LIMIT_LOWER_THEN_LOW_LIMIT:
    "コスト上限はコスト下限より低く限設定してください。",
  INVALID_INPUT: "無効入力",
  FIELD_IS_REQUIRED: (field: string) => `${field}を入力ください`,
  OUT_OF_RANGE: "範囲外です",
};
